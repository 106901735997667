export default defineNuxtRouteMiddleware((to,from) => {
    const isEmailVerified = computed(() => useAuthManager().authUser.value.is_email_verified)
    const isAccessingEmailVerificationPage = to.name == 'verify-email'; 
    const hasNoVerificationUrlLink = to.query.url == null || to.query.url == "";

    if (!isEmailVerified.value) {
        if (isAccessingEmailVerificationPage && hasNoVerificationUrlLink) {
            return navigateTo({name:"verify-your-email"})
        }
        return;
    }else {
            return navigateTo({name:'dashboard'});
    }
    
});